import Page404 from "@/pages/Page404";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

const RouteRedirects = () => {
  const pathname = useLocation().pathname;
  const searchParams = useSearchParams()[0];

  const tab = searchParams.get("tab");

  if (pathname === "/settings" && tab === "integrations") {
    return <Navigate to="/configuration/integration" />;
  }

  if (pathname.includes("/marketing-automation/")) {
    const id = pathname.split("/")[2] ?? "";
    if (id) {
      return <Navigate to={`/slack-broadcasting/analytics/${id}`} />;
    }
  }

  return <Page404 />;
};

export default RouteRedirects;
