/* eslint-disable max-lines */
import Tiptap from "@/components/tiptap/Tiptap";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Card, CardContent } from "@/components/ui/card";
import { SlackCodeStyle } from "@/components/common/SlackCodeStyle";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import useGetSlackConversation from "@/hooks/useGetSlackConversation";
import { RequestStatusType, RequestType } from "@/types/requests";
import {
  getTime,
  processConversationData,
  renderFileType,
} from "@/utils/Kanban/utils";
import { emptyList, emptyObject } from "@/utils/empty";
import { has, truncate } from "lodash";
import { ChevronDown, ChevronUp, Group, ListTodo } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import DefaultProfileIcon from "../../../images/icons/ic_default_slack_profile.png";
import { useLiveQuery } from "dexie-react-hooks";
import { getThenaDB } from "@/db";
import { getMomentForSecondsTs } from "@/utils/dateUtils";
import ConversationLoader from "./loaders/ConversationLoader";
import { decode } from "html-entities";
import "./editorStyle.css";
import { Skeleton } from "@/components/ui/skeleton";
import ConversationLoaderProgress from "@/components/common/ConversationLoaderProgress";
import { useKanbanStore } from "@/store/kanbanStore";
import { MODAL_TYPES } from "@/constants/kanban";
import ConversationProfileCard from "./ConversationProfileCard";
import { getUserPresence } from "@/api/kanban";
import { useQuery } from "@tanstack/react-query";

const ReplyBox = styled.div``;

const Wrapper = styled.div<{ $isEmail?: boolean }>`
  pre {
    overflow-x: auto;
  }
  a {
    word-wrap: break-word;
    word-break: break-word;
    color: var(--color-text-info);
  }
  ${({ $isEmail }) => {
    if (!$isEmail) {
      return `
        .ptagspacing p:empty {
    min-height: 20px;
  }
      `;
    } else {
      return `
      .ptagspacing p:empty {
         min-height: 4px;
  }
      .ptagspacing p:not(:first-child) {
        margin-top: 10px;
  }
      `;
    }
  }}
`;
const IndicatorLabel = styled.div`
  padding: 2px 8px;
  border-radius: 6px;
  background: var(--color-bg-elevated);
  color: var(--color-text);
`;

type Props = {
  hideEditor?: boolean;
  cardData: RequestType;
};

function chunkArray(array: string[], chunkSize: number) {
  const result: any = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

export function useFetchSlackPresence(ids) {
  const fetchAllData = async () => {
    const chunks = chunkArray(ids, 5);
    const promises = chunks.map((chunk) => getUserPresence(chunk));
    const results = await Promise.all(promises);
    return results.flat();
  };

  return useQuery({
    queryKey: ["fetchAllData", ids],
    queryFn: fetchAllData,
    enabled: ids.length > 0,
  });
}

export const getImageFallback = (userName: any) => {
  if (typeof userName === "string") {
    return (
      userName.slice(0, 1).toUpperCase() || (
        <img src={DefaultProfileIcon} className="rounded-full" />
      )
    );
  }
  return <img src={DefaultProfileIcon} className="rounded-full" />;
};

const Message = ({ html, className }: any) => {
  return (
    <div>
      <SlackCodeStyle />
      <div
        className={`text-sm text-[var(--color-text-body)] ${className}`}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </div>
  );
};

const Conversation = ({ hideEditor = false, cardData }: Props) => {
  const [showReply, setShowReply] = useState("");
  const [isRepliedHidden, setRepliedHidden] = useState(false);
  const isEmail = cardData?.original_message_source === "EMAIL";
  const relationshipData = useLiveQuery(() => {
    return getThenaDB().relationships.get(cardData?.relationship_id || "");
  }, [cardData?.relationship_id]);
  const { data, isFetching: threadLoading } = useGetSlackConversation(
    cardData._id
  );

  const modal = useKanbanStore((state) => state.modal);
  const isModalOpen = modal?.type === MODAL_TYPES.KANBAN_DRAWER;
  const [isFirstReFetch, setIsFirstReFetch] = useState(false);
  const isConversationLoading = useKanbanStore(
    (state) => state.isConversationLoading
  );
  const [threadDetails, setThreadDetails] = useState<any>([]);

  useEffect(() => {
    if (Array.isArray(data) && !threadLoading) {
      setThreadDetails(data);
    }
  }, [data, threadLoading, cardData._id]);

  useEffect(() => {
    const isModalOpen =
      useKanbanStore.getState().modal?.type === MODAL_TYPES.KANBAN_DRAWER;
    if (isModalOpen && !isConversationLoading) {
      setIsFirstReFetch(true);
    }
  }, [isConversationLoading]);

  useEffect(() => {
    if (cardData?._id) {
      setIsFirstReFetch((prev) => {
        if (prev) {
          return false;
        }
        return prev;
      });
    }
  }, [cardData?._id]);

  useEffect(() => {
    if (!isModalOpen) {
      setIsFirstReFetch(false);
    }
  }, [isModalOpen]);

  const sortedThreadDetails = useMemo(() => {
    return [...(threadDetails || [])]?.sort((a: any, b: any) => {
      return a.ts - b.ts;
    });
  }, [threadDetails]);

  const defaultThreadTs = sortedThreadDetails?.[0]?.ts;
  useEffect(() => {
    setShowReply(defaultThreadTs);
  }, [defaultThreadTs]);

  const isSourceSlack = cardData?.original_message_source === "SLACK";

  const channelId = isSourceSlack
    ? relationshipData?.channel_id ?? ""
    : cardData?.relationship_channel_id ?? "";

  const conversationList = useMemo(() => {
    return processConversationData(sortedThreadDetails);
  }, [sortedThreadDetails]);

  const slackIdList = useMemo(() => {
    return conversationList?.reduce((acc, item) => {
      const parentID = item.user_info?.id;
      const childIds = item.childReplies?.map((reply) => reply.user_info?.id);
      if (parentID) {
        acc.add(parentID);
      }
      if (childIds) {
        childIds.forEach((id) => acc.add(id));
      }
      return acc;
    }, new Set());
  }, [conversationList]);

  const { data: presenceData } = useFetchSlackPresence([...slackIdList]);

  if (threadLoading) {
    return <ConversationLoader />;
  }

  const renderEmailInfo = (item: any) => {
    const emailInfo = item?.email || emptyObject;
    const toList = emailInfo.to || emptyList;

    if (toList.length === 0) {
      return null;
    }

    const renderWithTooltip = (text = "", len = 20, className = "") => {
      const isTruncated = text.length > len;
      if (isTruncated) {
        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span
                  className={className}
                  style={{ color: "var(--color-text)" }}
                >
                  {truncate(text, { length: len })}
                </span>
              </TooltipTrigger>
              <TooltipContent>{text}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      }
      return (
        <span style={{ color: "var(--color-text)" }} className={className}>
          {text}
        </span>
      );
    };

    const renderEmailAndNameList = (list: any[]) => {
      return list.map((info: any) => {
        if (typeof info === "string") {
          return (
            <div className="flex items-center">
              {info ? renderWithTooltip(info, 25, "email-email-text") : null}
            </div>
          );
        }
        return (
          <div className="flex items-center">
            {renderWithTooltip(info.email, 25, "email-email-text")}
          </div>
        );
      });
    };

    const PopupContent = () => {
      return (
        <div
          style={{
            width: "100%",
            fontFamily: "var(--global-font)",
          }}
        >
          {emailInfo.from ? (
            <div className="flex mb-[8px] gap-1 ">
              <div className="flex email-view-row text-[var(--color-gray-6)]">
                From:
              </div>
              <div className="flex items-center">
                {renderWithTooltip(
                  emailInfo.from.email,
                  25,
                  "email-email-text"
                )}
              </div>
            </div>
          ) : null}
          <div className="flex gap-1">
            <div className="flex email-view-row text-[var(--color-gray-6)]">
              To:
            </div>
            <div>{renderEmailAndNameList(emailInfo.to)}</div>
          </div>
          {emailInfo.cc?.length > 0 && (
            <div className="flex mt-[8px] gap-1">
              <div className="flex email-view-row text-[var(--color-gray-6)]">
                Cc:
              </div>
              <div>{renderEmailAndNameList(emailInfo.cc)}</div>
            </div>
          )}
          {emailInfo.bcc?.length > 0 && (
            <div className="flex mt-[8px] gap-1">
              <div className="flex email-view-row text-[var(--color-gray-6)]">
                Bcc:
              </div>
              <div>{renderEmailAndNameList(emailInfo.bcc)}</div>
            </div>
          )}
        </div>
      );
    };

    return (
      <div className="flex items-center relative top-[-2px] gap-[4px] email-to-info mb-[2px] text-[var(--color-text)]">
        To:
        {toList.map((info: any) => {
          const firstName = (info?.name?.split(" ")?.[0] || "").trim();
          const infoToShow = firstName
            ? firstName
            : info?.email?.split("@")?.[0] || "";

          return (
            <div>
              {renderWithTooltip(infoToShow, 20)}
              {toList.length > 1 ? ", " : null}
            </div>
          );
        })}
        <Popover>
          <PopoverTrigger asChild>
            <ChevronDown className="cursor-pointer" size={12} />
          </PopoverTrigger>
          <PopoverContent className="w-fit">
            <PopupContent />
          </PopoverContent>
        </Popover>
      </div>
    );
  };

  const renderFiles = (files: any, isEmail = false) => {
    return (
      <>
        {files?.map((file: any) => (
          <div key={file?.id}>
            <div
              className="w-24 h-20 border border-solid border-[color:var(--modal-border)] rounded-md cursor-pointer"
              onClick={() =>
                window.open(isEmail ? file?.url : file?.private_url, "_blank")
              }
            >
              <div className="h-1/2 flex flex-col justify-center items-center">
                {renderFileType(file?.mimetype)}
              </div>
              <div className="flex flex-col justify-center items-center text-xs bg-[var(--color-bg-elevated)] h-1/2">
                <span>
                  {truncate(isEmail ? file?.originalName : file?.name, {
                    length: 13,
                  })}
                </span>
                <span>{file?.filetype?.toUpperCase()}</span>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const threadTsToReply = conversationList?.find(
    (item) => item.ts === cardData.original_message_ts && item.thread_ts
  )?.thread_ts;

  const renderChild = (item, reply, index) => {
    if (!isRepliedHidden && showReply === item?.ts) {
      if (!has(reply, "user_info") && !has(reply, "html")) {
        return (
          <div className="flex my-[10px] pl-[42px]">
            <Skeleton className="w-9 h-9 rounded-full" />
            <div className="flex-1 ml-3">
              <Skeleton className="w-48 h-[14px]" />
              <Skeleton className="w-96 h-[14px] mt-2" />
            </div>
          </div>
        );
      }
      const checkUserPresence = presenceData?.find(
        (presence) => presence.userId === reply.user_info?.id
      );

      return (
        <div key={reply.ts + index}>
          <div className="pt-1 pb-0 mt-[4px]">
            <CardContent className="p-0 grid gap-4 pl-11 ptagspacing break-words">
              <div className="flex items-start gap-3">
                {isSourceSlack ? (
                  <ConversationProfileCard
                    userInfo={reply.user_info}
                    presence={checkUserPresence?.presence}
                    slackId={reply.user_info?.id}
                  >
                    <Avatar className="hidden h-9 w-9 sm:flex cursor-pointer">
                      <AvatarImage src={reply.user_info?.image} alt="Avatar" />
                      <AvatarFallback>
                        {getImageFallback(reply?.user_info?.display_name)}
                      </AvatarFallback>
                    </Avatar>
                  </ConversationProfileCard>
                ) : (
                  <Avatar className="hidden h-9 w-9 sm:flex">
                    <AvatarImage src={reply.user_info?.image} alt="Avatar" />
                    <AvatarFallback>
                      {getImageFallback(reply?.user_info?.display_name)}
                    </AvatarFallback>
                  </Avatar>
                )}
                <div className="grid w-full">
                  <div className="flex items-center justify-start gap-2">
                    <div className="text-sm font-medium group leading-none ">
                      {isSourceSlack ? (
                        <ConversationProfileCard
                          userInfo={reply.user_info}
                          presence={checkUserPresence?.presence}
                          slackId={reply.user_info?.id}
                        >
                          <span className="text-color-text cursor-pointer">
                            {truncate(
                              reply?.user_info?.display_name || "Unnamed",
                              {
                                length: 60,
                              }
                            )}
                          </span>
                        </ConversationProfileCard>
                      ) : (
                        truncate(reply?.user_info?.display_name || "Unnamed", {
                          length: 60,
                        })
                      )}
                    </div>
                    <div className="text-xs text-muted-foreground">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            {getTime(
                              getMomentForSecondsTs(reply?.ts).fromNow()
                            )}
                          </TooltipTrigger>
                          <TooltipContent>
                            {getMomentForSecondsTs(reply?.ts)
                              .format("MMM DD")
                              .toString() +
                              " at " +
                              getMomentForSecondsTs(reply?.ts)
                                .format("hh:mm A")
                                .toString()}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                    {isMsgCreatedFromThread(reply.ts) ? (
                      <IndicatorLabel className="flex items-center gap-1 text-xs font-medium">
                        <ListTodo
                          size={12}
                          color="var(--color-icon-interactive)"
                        />
                        Request detected
                      </IndicatorLabel>
                    ) : null}
                  </div>
                  <div className="mt-1.5">{renderEmailInfo(reply)}</div>
                  {isEmail ? (
                    <div className="w-[530px] break-words">
                      <shadow-dom html={reply?.email?.html ?? ""} />
                    </div>
                  ) : (
                    <Message
                      html={decode(reply?.html ?? "")}
                      className="w-[530px] break-words"
                    />
                  )}
                  {reply?.file?.length > 0 && (
                    <div className="flex flex-wrap gap-2 mt-2 mb-3">
                      {renderFiles(reply?.file)}
                    </div>
                  )}
                  {isEmail && reply?.email?.attachments?.length > 0 && (
                    <div className="flex flex-wrap gap-2 mt-2 mb-3">
                      {renderFiles(reply?.email?.attachments, true)}
                    </div>
                  )}
                </div>
              </div>
            </CardContent>
          </div>
        </div>
      );
    }
    return null;
  };

  const isMsgCreatedFromThread = (replyTs: string) => {
    return replyTs === cardData.original_message_ts;
  };

  const isMsgGrouped = (item: any) => {
    if (!data.length) {
      return false;
    }

    if (item.isTopLevel) {
      if (
        (cardData.original_message_thread_ts ||
          cardData.original_message_ts) !== item.ts
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const renderLoader = () => {
    if (isConversationLoading && !isFirstReFetch) {
      return <ConversationLoaderProgress />;
    }
    return null;
  };

  return (
    <>
      {renderLoader()}
      {conversationList?.map((item: any, index) => {
        const checkUserPresence = presenceData?.find(
          (presence) => presence.userId === item.user_info?.id
        );
        const checkFirstChildUserPresence = presenceData?.find(
          (presence) =>
            presence.userId === item.childReplies?.[0]?.user_info?.id
        );

        return (
          <Wrapper key={item.ts + index}>
            <Card className="border-none mt-4 text-[--color-text] shadow-none editor">
              <CardContent className="p-[20px] pt-0 overflow-x-hidden">
                <div className="flex items-start gap-3">
                  {has(item, "user_info") ? (
                    isSourceSlack ? (
                      <ConversationProfileCard
                        userInfo={item.user_info}
                        presence={checkUserPresence?.presence}
                        slackId={item.user_info?.id}
                      >
                        <Avatar className="hidden h-9 w-9 sm:flex cursor-pointer">
                          <AvatarImage
                            src={item.user_info?.image}
                            alt="Avatar"
                          />
                          <AvatarFallback>
                            {getImageFallback(item.user_info?.display_name)}
                          </AvatarFallback>
                        </Avatar>
                      </ConversationProfileCard>
                    ) : (
                      <Avatar className="hidden h-9 w-9 sm:flex">
                        <AvatarImage src={item.user_info?.image} alt="Avatar" />
                        <AvatarFallback>
                          {getImageFallback(item.user_info?.display_name)}
                        </AvatarFallback>
                      </Avatar>
                    )
                  ) : (
                    <Skeleton className="w-9 h-9 rounded-full shrink-0" />
                  )}
                  <div className="grid w-full">
                    {has(item, "user_info") ? (
                      <div className="flex items-center justify-start gap-2">
                        <div className="text-sm font-medium leading-none">
                          {isSourceSlack ? (
                            <ConversationProfileCard
                              userInfo={item.user_info}
                              presence={checkUserPresence?.presence}
                              slackId={item.user_info?.id}
                            >
                              <span className="text-color-text cursor-pointer">
                                {truncate(
                                  item.user_info.display_name || "Unnamed",
                                  {
                                    length: 60,
                                  }
                                )}
                              </span>
                            </ConversationProfileCard>
                          ) : (
                            truncate(item.user_info.display_name || "Unnamed", {
                              length: 60,
                            })
                          )}
                        </div>
                        <div className="text-xs text-muted-foreground">
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger className="cursor-default">
                                {getTime(
                                  getMomentForSecondsTs(item?.ts).fromNow()
                                )}
                              </TooltipTrigger>
                              <TooltipContent>
                                {getMomentForSecondsTs(item?.ts)
                                  .format("MMM DD")
                                  .toString() +
                                  " at " +
                                  getMomentForSecondsTs(item?.ts)
                                    .format("hh:mm A")
                                    .toString()}
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                        {isMsgGrouped(item) ? (
                          <IndicatorLabel className="flex items-center gap-1 text-xs font-medium">
                            <Group
                              size={12}
                              color="var(--color-icon-interactive)"
                            />
                            Grouped message
                          </IndicatorLabel>
                        ) : null}
                      </div>
                    ) : (
                      <Skeleton className="w-48 h-[14px]" />
                    )}
                    <div className="mt-1.5">{renderEmailInfo(item)}</div>
                    <>
                      {isEmail && (
                        <div>
                          {item?.email?.subject ? (
                            <span className="font-semibold text-sm">{`Subject: ${item?.email?.subject}`}</span>
                          ) : (
                            <Skeleton className="w-48 h-[14px]" />
                          )}
                        </div>
                      )}
                      <div>
                        {isEmail ? (
                          <div className="w-[580px] break-words">
                            <shadow-dom html={item?.email?.html ?? ""} />
                          </div>
                        ) : has(item, "html") ? (
                          <Message
                            html={decode(item.html ?? "")}
                            className="w-[580px] break-words"
                          />
                        ) : (
                          <Skeleton className="w-96 h-[14px] mt-2 relative left-[-2px]" />
                        )}
                      </div>
                    </>
                    {item?.file?.length > 0 && (
                      <div className="flex flex-wrap gap-2 mt-2 mb-3">
                        {renderFiles(item?.file)}
                      </div>
                    )}
                    {isEmail && item?.email?.attachments?.length > 0 && (
                      <div className="flex flex-wrap gap-2 mt-2 mb-3">
                        {renderFiles(item?.email?.attachments, true)}
                      </div>
                    )}
                    {item?.childReplies?.length > 1 && (
                      <div className="gap-1 text-sm inline-flex flex-wrap items-center p-1 cursor-pointer text-[color:var(--color-purple-1)] justify-between w-full">
                        {item?.childReplies?.length > 1 ? (
                          showReply !== item?.ts ? (
                            <span
                              className="mr-10 flex items-center gap-1 text-[var(--color-text)]"
                              onClick={() => {
                                setShowReply(item?.ts);
                                setRepliedHidden(false);
                              }}
                            >
                              <ChevronDown size={16} /> show replies
                            </span>
                          ) : (
                            <span
                              className="mr-10 flex items-center gap-1 text-[var(--color-text)]"
                              onClick={() => {
                                setShowReply("");
                                setRepliedHidden(true);
                              }}
                            >
                              <ChevronUp size={16} /> hide replies
                            </span>
                          )
                        ) : (
                          <div></div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {/* rendering the root message ends */}
                {/* rendering the first item in replies starts*/}
                {/* rendering first reply */}
                {item?.childReplies?.length > 0 && (
                  <CardContent className="p-0 grid gap-4 pl-11 mt-2">
                    <div className="flex items-start gap-3 w-full">
                      {has(item, "childReplies[0].user_info") ? (
                        isSourceSlack ? (
                          <ConversationProfileCard
                            presence={checkFirstChildUserPresence?.presence}
                            userInfo={item.childReplies[0].user_info}
                            slackId={item.childReplies[0].user_info?.id}
                          >
                            <Avatar className="hidden h-9 w-9 sm:flex cursor-pointer">
                              <AvatarImage
                                alt="Avatar"
                                src={item.childReplies[0].user_info.image}
                              />
                              <AvatarFallback>
                                {getImageFallback(
                                  item.childReplies[0].user_info?.display_name
                                )}
                              </AvatarFallback>
                            </Avatar>
                          </ConversationProfileCard>
                        ) : (
                          <Avatar className="hidden h-9 w-9 sm:flex">
                            <AvatarImage
                              alt="Avatar"
                              src={item.childReplies[0].user_info.image}
                            />
                            <AvatarFallback>
                              {getImageFallback(
                                item.childReplies[0].user_info?.display_name
                              )}
                            </AvatarFallback>
                          </Avatar>
                        )
                      ) : (
                        <Skeleton className="w-9 h-9 rounded-full shrink-0 relative left-[-2px]" />
                      )}
                      <div>
                        {has(item, "childReplies[0].user_info") ? (
                          <div className="flex items-center justify-start gap-2">
                            <div className="text-sm font-medium leading-none">
                              {isSourceSlack ? (
                                <ConversationProfileCard
                                  userInfo={item.user_info}
                                  presence={checkUserPresence?.presence}
                                  slackId={item.user_info?.id}
                                >
                                  <span className="text-color-text cursor-pointer">
                                    {truncate(
                                      item.childReplies[0].user_info
                                        .display_name || "Unnamed",
                                      {
                                        length: 60,
                                      }
                                    )}
                                  </span>
                                </ConversationProfileCard>
                              ) : (
                                truncate(
                                  item.childReplies[0].user_info.display_name ||
                                    "Unnamed",
                                  {
                                    length: 60,
                                  }
                                )
                              )}
                            </div>
                            <div className="text-xs text-muted-foreground">
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger>
                                    {getTime(
                                      getMomentForSecondsTs(
                                        item?.childReplies?.[0]?.ts
                                      ).fromNow()
                                    )}
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    {getMomentForSecondsTs(
                                      item?.childReplies?.[0]?.ts
                                    )
                                      .format("MMM DD")
                                      .toString() +
                                      " at " +
                                      getMomentForSecondsTs(
                                        item?.childReplies?.[0]?.ts
                                      )
                                        .format("hh:mm A")
                                        .toString()}
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                            {isMsgCreatedFromThread(item.childReplies[0].ts) ? (
                              <IndicatorLabel className="flex items-center gap-1 text-xs font-medium">
                                <ListTodo
                                  size={12}
                                  color="var(--color-icon-interactive)"
                                />
                                Request detected
                              </IndicatorLabel>
                            ) : null}
                          </div>
                        ) : (
                          <Skeleton className="w-48 h-[14px] relative left-[-2px]" />
                        )}
                        <div className="mt-1.5">
                          {renderEmailInfo(item?.childReplies?.[0])}
                        </div>
                        <div>
                          {isEmail ? (
                            <div className="w-[530px] break-words">
                              <shadow-dom
                                html={
                                  item?.childReplies?.[0]?.email?.html ?? ""
                                }
                              />
                            </div>
                          ) : has(item, "childReplies[0].html") ? (
                            <Message
                              html={decode(item.childReplies[0].html ?? "")}
                              className="w-[530px] break-words"
                            />
                          ) : (
                            <Skeleton className="w-96 h-[14px] mt-2 relative left-[-2px]" />
                          )}
                        </div>
                        {item?.childReplies?.[0]?.file?.length > 0 && (
                          <div className="flex flex-wrap gap-2 mt-2 mb-3">
                            {renderFiles(item?.childReplies?.[0]?.file)}
                          </div>
                        )}
                        {isEmail &&
                          item?.childReplies?.[0]?.email?.attachments?.length >
                            0 && (
                            <div className="flex flex-wrap gap-2 mt-2 mb-3">
                              {renderFiles(
                                item?.childReplies?.[0]?.email?.attachments,
                                true
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  </CardContent>
                )}
                {/* rendering the first item in replies ends*/}
                {/* rendering second till last replies starts*/}
                {item?.childReplies?.map((reply, index) => {
                  if (item?.childReplies?.length >= 1 && index == 0) {
                    return null;
                  }
                  // TODO: commented out for now, need to fix this
                  // const isMessageWithin1MinOfLast = false;
                  return renderChild(item, reply, index);
                })}
                {/* rendering second till last replies ends*/}
                {/* //TODO: Add LD flag */}
                {/* {!hideEditor && showEditor === item?.ts && (
                <ReplyBox className="relative ml-9 mt-2">
                  <Tiptap
                    setThreadDetails={setThreadDetails}
                    ts={item.ts}
                    threadTs={item?.ts}
                    channelId={channelId}
                    item={item}
                    isEmail={isEmail}
                  />
                </ReplyBox>
              )} */}
              </CardContent>
            </Card>
            {!hideEditor && (
              <ReplyBox className="relative ml-[54px] mt-2">
                <Tiptap
                  setThreadDetails={setThreadDetails}
                  ts={
                    cardData.original_message_thread_ts ||
                    cardData.original_message_ts ||
                    ""
                  }
                  threadTs={threadTsToReply}
                  channelId={channelId}
                  item={item}
                  isEmail={isEmail}
                  isRequestMerged={
                    cardData.status === ("MERGED" as RequestStatusType)
                  }
                  _id={cardData?._id}
                  isInternalHelpDesk={cardData?.is_internal_helpdesk}
                />
              </ReplyBox>
            )}
          </Wrapper>
        );
      })}
    </>
  );
};

export default Conversation;
