import MsTeamsLogo from "@/components/common/MSTeamsLogo/MsTeamsLogo";
import {
  Building2,
  Cable,
  Contact2,
  FileStack,
  FileSymlink,
  KeyRound,
  Layers,
  LineChart,
  ListTodo,
  LucideIcon,
  Mails,
  Megaphone,
  MessagesSquare,
  Settings,
  Ticket,
  Wrench,
  Sparkles,
} from "lucide-react";

interface Link {
  title: string;
  label: string;
  icon: LucideIcon;
  variant: "ghost";
  route?: string;
  subLinks?: SubLink[];
  query?: string;
}

interface SubLink {
  title: string;
  link: string;
  variant?:
    | "link"
    | "ghost"
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | null
    | undefined;
}

export interface MenuItem {
  isCollapsed?: boolean;
  label: string;
  links: Link[];
}

export const menuItems: MenuItem[] = [
  {
    label: "Customer support",
    links: [
      {
        title: "Requests",
        label: "",
        icon: ListTodo,
        variant: "ghost",
        route: "/requests",
      },
      {
        title: "Tickets",
        label: "",
        icon: Ticket,
        variant: "ghost",
        route: "/tickets",
      },
      {
        title: "External links",
        label: "",
        icon: FileSymlink,
        variant: "ghost",
        route: "/external-links",
      },
      {
        title: "Saved views",
        label: "",
        icon: Layers,
        variant: "ghost",
        route: "/saved-views",
      },
      {
        title: "AI assist",
        label: "",
        icon: Sparkles,
        variant: "ghost",
        route: "customer-support/ai-assist",
      },
      {
        title: "Analytics",
        label: "",
        icon: LineChart,
        variant: "ghost",
        route: "/analytics",
      },
    ],
  },
  {
    label: "Internal helpdesk",
    links: [
      {
        title: "Requests",
        label: "",
        icon: ListTodo,
        variant: "ghost",
        route: "/request",
        query: "tab=internal-helpdesk",
      },
      {
        title: "Tickets",
        label: "",
        icon: Ticket,
        variant: "ghost",
        route: "/internal-helpdesk/tickets",
        // query: "tab=internal-helpdesk",
      },
      {
        title: "Saved views",
        label: "",
        icon: Layers,
        variant: "ghost",
        route: "/internal-views",
      },
      // {
      //   title: "AI assist",
      //   label: "",
      //   icon: Sparkles,
      //   variant: "ghost",
      //   route: "/internal-helpdesk/ai-assist",
      // }
    ],
  },
  {
    label: "Engagement",
    links: [
      {
        title: "Broadcasts",
        label: "",
        icon: Megaphone,
        variant: "ghost",
        route: "/slack-broadcasting",
      },
    ],
  },
  {
    label: "Configuration",
    links: [
      {
        title: "Setup",
        label: "",
        icon: Wrench,
        variant: "ghost",
        route: "/configuration/setup",
      },
      {
        title: "Settings",
        label: "",
        icon: Settings,
        variant: "ghost",
        route: "/configuration/settings",
      },
      {
        title: "Accounts",
        label: "",
        icon: Building2,
        variant: "ghost",
        route: "/configuration/accounts",
      },
      {
        title: "CRM",
        label: "",
        icon: Contact2,
        variant: "ghost",
        route: "/configuration/crm-connect",
      },
      {
        title: "Email",
        label: "",
        icon: Mails,
        variant: "ghost",
        route: "/configuration/email",
      },
      {
        title: "Web chat",
        label: "",
        icon: MessagesSquare,
        variant: "ghost",
        route: "/configuration/web-chat",
      },
      {
        title: "Microsoft Teams",
        label: "",
        icon: MsTeamsLogo as any,
        variant: "ghost",
        route: "/configuration/ms-teams",
      },
      {
        title: "Snippets",
        label: "",
        icon: FileStack,
        variant: "ghost",
        route: "/snippets",
      },
      {
        title: "Integrations",
        label: "",
        icon: Cable,
        variant: "ghost",
        route: "/configuration/integration",
      },
      {
        title: "API key",
        label: "",
        icon: KeyRound,
        variant: "ghost",
        route: "/configuration/apikey",
      },
    ],
  },
];

export const customerPortalMenuItems: MenuItem[] = [
  {
    label: "Customer support",
    links: [
      {
        title: "Requests",
        label: "",
        icon: ListTodo,
        variant: "ghost",
        route: "/requests",
      },
      {
        title: "Manage users",
        label: "",
        icon: Settings,
        variant: "ghost",
        route: "/members",
      },
    ],
  },
];
