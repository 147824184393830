import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import LogRocket from "logrocket";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App.tsx";
import ErrorBoundary from "./ErrorBoundary.tsx";
import AuthLayer from "./context/AuthLayer.tsx";
import { ThemeProvider } from "./context/theme-provider.tsx";
import Layout from "./layout/Layout.tsx";
import Bootstrap from "./modules/Bootstrap/Bootstrap.tsx";
import { useGlobalStorePersist } from "./store/globalStorePersist.ts";
import { emitInfoToSW, registerServiceWorker } from "./utils/domUtils.ts";
import { init } from "commandbar";
import { COMMANDBAR_ORG_ID, IS_SENTRY_ENABLED } from "./config/constants.ts";
import "./utils/customWebComponents.ts";
import { insertOrUpdateDataToDuckDB } from "./utils/duckDB.ts";
import * as Sentry from "@sentry/react";

init(COMMANDBAR_ORG_ID);

if (import.meta.env.PROD) {
  LogRocket.init("mqlujp/thena-2");

  if (IS_SENTRY_ENABLED) {
    Sentry.init({
      dsn: "https://ecdd1816957a8fd683e88cefe9f30bdc@o4504892814065664.ingest.us.sentry.io/4507463863500800",
      integrations: [
        Sentry.browserTracingIntegration({}),
        Sentry.replayIntegration({
          networkDetailAllowUrls: [/^https:\/\/bolt\.thena\.ai\/.*/i],
          networkResponseHeaders: ["X-Request-Id"],
          networkRequestHeaders: ["host"],
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      console.error("QueryCache error: ", error);
    },
  }),
});

registerServiceWorker();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.addEventListener("message", (event) => {
    if (event.data && event.data.type) {
      switch (event.data.type) {
        case "SW_ACTIVATED":
          useGlobalStorePersist.dispatch({
            type: "SET_IS_SW_ACTIVATED",
            payload: { isSWActivated: true },
          });
          break;

        case "SW_CONSOLE":
          console.log("SW_CONSOLE: ", event.data.data);
          break;

        case "SW_DB_UPDATE": {
          if (event?.data?.table && Array.isArray(event?.data?.dataList)) {
            insertOrUpdateDataToDuckDB({
              table: event.data.table,
              dataList: event.data.dataList,
            });
          }
          break;
        }

        default:
          break;
      }
    }
  });
}

emitInfoToSW({
  type: "HEARTBEAT",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <ErrorBoundary>
    <Router>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider storageKey="vite-ui-theme">
          <AuthLayer>
            <Bootstrap>
              <Layout>
                <App />
              </Layout>
            </Bootstrap>
          </AuthLayer>
        </ThemeProvider>
      </QueryClientProvider>
    </Router>
  </ErrorBoundary>
  // </React.StrictMode>
);
