import { useAuthStorePersist } from "@/store/authStorePersist";
import { sendErrorToLogRocket } from "./errors";
import { CUSTOMER_PORTAL_USER_ROLE } from "@/constants/user";

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isMacDevice = () => navigator.userAgent.indexOf("Mac") > -1;

export const emitInfoToSW = (payload) => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(function (registration) {
      if (!registration.active) return console.log("Service worker not active");
      registration.active.postMessage(payload);
    });
  }
};

export const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    console.log("Service worker registration started!");
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register(new URL("/sw.js", import.meta.url))
        .then((reg) => {
          console.log("Service worker registered!", reg);
          reg.addEventListener("error", function (e) {
            console.log(`[Main] SW Error: ${e}`);
            sendErrorToLogRocket({
              error: "[Main] SW Error",
              extra: {
                error: e,
              },
            });
          });
        })
        .catch((err) => {
          console.log("Service worker registration failed:", err);
          sendErrorToLogRocket({
            error: "Service worker registration failed",
            extra: {
              error: err,
            },
          });
        });
    });
  }
};

export const emitBroadcastMsg = (channelName: string, payload: any) => {
  const channel = new BroadcastChannel(channelName);
  channel.postMessage(payload);
  channel.close();
};

export const getSubdomainInfo = () => {
  const subdomain = window.location.host.split(".")[0];
  if (subdomain.includes("localhost") && subdomain.includes(":")) {
    return {
      isLocalhost: true,
      subdomain: "",
    };
  }
  return {
    isLocalhost: false,
    subdomain,
  };
};

const NON_CUSTOMER_PORTAL_SUBDOMAINS = [
  "app",
  "thena-web-app-dev",
  "thena-web-staging",
  "thena-web-app-alpha",
  "thena-web-pilot-1",
  "pilot1",
];

export const getIsCustomerPortal = () => {
  return useAuthStorePersist
    .getState()
    .user?.roles?.includes?.(CUSTOMER_PORTAL_USER_ROLE);
  // return true;
  // // const subdomainInfo = getSubdomainInfo();
  // // if (subdomainInfo.isLocalhost) {
  // //   return false;
  // // }
  // // return !NON_CUSTOMER_PORTAL_SUBDOMAINS.includes(subdomainInfo.subdomain);
};

export const windowConfirmDialog = (message): Promise<boolean> => {
  return new Promise((resolve) => {
    const confirmed = window.confirm(message);
    resolve(confirmed);
  });
};
