import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";
import MacroList from "./MacrosList";
import { FileInput, FilePlus2 } from "lucide-react";

const MacroSuggestions = {
  items: async ({ query }) => {
    const slashCommands = [
      {
        name: "Create a new snippet",
        macroCommand: "CREATE_MACRO",
        beforeIcon: <FileInput size={16} />,
      },
      {
        name: "Snippets",
        macroCommand: "SHOW_MACROS",
        beforeIcon: <FilePlus2 size={16} />,
      },
    ];
    return {
      query,
      slashCommands,
    };
  },

  render: () => {
    let reactRenderer;
    let popup;


    const closePopover = () => {
      if (popup) {
        popup[0].hide();
      }
    };

    return {
      onStart: (props) => {
        if (!props.clientRect) {
          return;
        }
        reactRenderer = new ReactRenderer(MacroList, {
          props: { ...props, closePopover },
          editor: props.editor,
        });

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "top-start",
        });
      },

      onUpdate(props) {
        reactRenderer.updateProps({ ...props, closePopover });

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        return reactRenderer.ref?.onKeyDown(props);
      },

      onExit() {
        popup?.[0].destroy();
        reactRenderer?.destroy();
      },
    };
  },
};

export default MacroSuggestions;
