import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";
import { getThenaDB } from "@/db";
import { InternalMentionList } from "./InternalMentionList";

export default {
  items: async ({ query }) => {
    const internalUsers = await getThenaDB().users.toArray();
    const filtered = internalUsers
      .filter(
        (item) =>
          item.slack_profile_display_name
            ?.toLowerCase()
            .replace(/ /g, "")
            ?.startsWith(query.toLowerCase()) ||
          item.slack_profile_real_name
            ?.toLowerCase()
            .replace(/ /g, "")
            ?.startsWith(query.toLowerCase())
      )
      .slice(0, 5);
    return filtered;
  },
  render: () => {
    let reactRenderer;
    let popup;
    return {
      onStart: (props) => {
        if (!props.clientRect) {
          return;
        }

        reactRenderer = new ReactRenderer(InternalMentionList, {
          props,
          editor: props.editor,
        });

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate(props) {
        reactRenderer.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        return reactRenderer.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        reactRenderer.destroy();
      },
    };
  },
};
