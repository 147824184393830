import { cn } from "@/utils";
import { buttonVariants } from "@/components/ui/button";
import {
  NavLink,
  To,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import { MenuItem } from "@/layout/menuItems";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  ASSISTANT_TYPE,
  AI_ASSIST_SETTINGS_PATH,
} from "@/constants/aiAssistant";
import useFocusedLiveQuery from "@/hooks/useFocusedLiveQuery";
import { getThenaDB } from "@/db";
import { useGlobalStore } from "@/store/globalStore";
import { getIsCustomerPortal, windowConfirmDialog } from "@/utils/domUtils";

const Divider = styled.div`
  /* margin-left: 8px; */
  border-bottom: 1px solid var(--highlight-1);
`;

export function Nav({
  routes,
  isCollapsed,
}: {
  routes: MenuItem[];
  isCollapsed: boolean;
}) {
  const searchParams = useSearchParams()[0];
  const flags = useFlags();
  const location = useLocation();
  const connector =
    useFocusedLiveQuery(() => {
      if (getThenaDB()) {
        return getThenaDB().connectors.toArray();
      }
    })?.[0] || {};

  const ticketSectionName = connector?.name ? (
    connector.name === "salesforce_cases" ? (
      "Salesforce Cases"
    ) : (
      <>
        <span className="capitalize">
          {connector.name?.replaceAll("_", " ")}
        </span>
        &nbsp;tickets
      </>
    )
  ) : (
    <span>Tickets</span>
  );

  const isCustomerPortal = getIsCustomerPortal();

  const dirtyMap = useGlobalStore((state) => state.formDirtyMap);
  const navigate = useNavigate();
  const onItemClick = async (e: any, itemRoute?: string) => {
    if (itemRoute?.includes("/configuration/settings")) {
      navigate(itemRoute ?? "#");
      useGlobalStore.getState().setFormDirtyMap({});
      return;
    }
    e.preventDefault();
    const isDirty = Object.values(dirtyMap).find((item) => item);
    let flag = true;
    if (isDirty) {
      flag = await windowConfirmDialog(
        "You have made changes, are you sure you want to navigate away without saving?"
      );
    }
    if (flag) {
      navigate(itemRoute ?? "#");
      useGlobalStore.getState().setFormDirtyMap({});
    }
  };
  return (
    <div
      data-collapsed={isCollapsed}
      className="group flex flex-col gap-6 py-2"
    >
      <nav className="flex flex-col gap-4 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
        {routes.map((route, index) => {
          return (
            <div className="flex flex-col gap-3" key={route.label}>
              {!isCustomerPortal && (
                <span className="text-[var(--color-text-placeholder)] text-xs px-2 font-medium">
                  {route.label}
                </span>
              )}
              <div className="w-full flex flex-col gap-1">
                {route.links.map((link, id) => {
                  if (link.route === "/external-links" && !flags.paragon) {
                    return null;
                  }
                  if (
                    link.route ===
                      AI_ASSIST_SETTINGS_PATH[
                        ASSISTANT_TYPE.CUSTOMER_SUPPORT
                      ] &&
                    !flags.aiAssistant
                  ) {
                    return null;
                  }
                  if (
                    link.route ===
                      AI_ASSIST_SETTINGS_PATH[
                        ASSISTANT_TYPE.INTERNAL_HELPDESK
                      ] &&
                    !flags.aiAssistant
                  ) {
                    return null;
                  }
                  if (link.title === "Tickets" && connector?.name?.length < 2) {
                    return null;
                  }
                  if (link.title === "Tickets" && !connector?.name) {
                    return null;
                  }

                  const finalRoute = link.query
                    ? `${link.route}?${link.query}`
                    : (link.route as To);

                  return (
                    <div className="w-full" key={id}>
                      <NavLink
                        key={id}
                        to={finalRoute}
                        end
                        className={({ isActive }) => {
                          const isViewActive =
                            searchParams.get("view") !== null;
                          return cn(
                            buttonVariants({
                              variant: link.variant,
                              size: "navItem",
                            }),
                            "justify-start",
                            ((link?.route === "/configuration/accounts" &&
                              location.pathname.includes(
                                "/configuration/accounts"
                              )) ||
                              (link?.route === "/slack-broadcasting" &&
                                location.pathname.includes(
                                  "/slack-broadcasting"
                                )) ||
                              (isActive && !isViewActive)) &&
                              " bg-muted dark:text-white",
                            "w-full px-2"
                          );
                        }}
                        onClick={(e) => onItemClick(e, finalRoute as string)}
                      >
                        {link.title === "Microsoft Teams" ? (
                          <span className="mr-2">
                            <link.icon />
                          </span>
                        ) : (
                          <link.icon className="mr-2 h-4 w-4" />
                        )}
                        {link.route === "/tickets"
                          ? ticketSectionName
                          : link.title}
                        {link.label && (
                          <span className={cn("ml-auto")}>{link.label}</span>
                        )}
                      </NavLink>
                    </div>
                  );
                })}
              </div>
              {index !== routes.length - 1 && <Divider />}
            </div>
          );
        })}
      </nav>
    </div>
  );
}
