/* eslint-disable max-lines */
import { DEFAULT_COLUMN_ORDER, STATUS_ORDER } from "@/constants/kanban";
import { getThenaDB } from "@/db";
import { useKanbanStore } from "@/store/kanbanStore";
import { useKanbanStorePersist } from "@/store/kanbanStorePersist";
import { RequestType, ViewsType } from "@/types/requests";
import { getDateObjectsFromRange } from "@/utils/dateUtils";
import { endOfDay, startOfDay } from "date-fns";
import Dexie from "dexie";
import { get, intersection, isEmpty, isEqual, union } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import {
  crmRelationDB,
  getFormattedKey,
  getRedactedKey,
} from "./useCRMFilterInit";
import { useKanbanCacheStore } from "@/store/kanbanCacheStore";
import { getFormattedOverflowValue } from "./useCRMInfo";
import BBPromise from "bluebird";
import { useGlobalStore } from "@/store/globalStore";
import useFocusedLiveQuery from "./useFocusedLiveQuery";
import { insertOrUpdateDataToDuckDB } from "@/utils/duckDB";
import { views } from "@/constants/views";
import {
  getDateRangeByPeriod,
  getRelationshipFilterKey,
  includesIgnoreCase,
} from "@/utils/requests";

BBPromise.config({ cancellation: true });

const NULL_CHECK_SORTING_FIELDS = [
  "lastReplyOrder",
  "firstResponseSlaOrder",
  "finalResolutionSlaOrder",
  "lastCustomerReplyOrder",
];

const useRequestsInit = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentView = searchParams.get("view");
  const savedViews = useFocusedLiveQuery(
    () => getThenaDB().views.toArray(),
    []
  );
  const isValidSavedView = useMemo(
    () => savedViews?.filter((item) => item._id === currentView).length,
    [savedViews, currentView]
  );

  useEffect(() => {
    if (currentView && isValidSavedView) {
      useKanbanStore.dispatch({
        type: "SET_CURRENT_VIEW",
        payload: {
          currentView,
        },
      });
    } else if (savedViews !== undefined) {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.delete("view");
        return newParams;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView, savedViews, isValidSavedView]);

  useEffect(() => {
    getThenaDB().tables.forEach((table) => {
      table.hook("creating", function (...creationProps) {
        // console.log("creating", table.name);
        if (table.name === "requests") {
          const createdRequest = creationProps[1];
          insertOrUpdateDataToDuckDB({
            table: "requests",
            dataList: [createdRequest],
          });
        }
      });
      table.hook("updating", function (...updationProps) {
        // console.log("updating", table.name);
        if (table.name === "requests") {
          const updatedRequest = updationProps[2];
          insertOrUpdateDataToDuckDB({
            table: "requests",
            dataList: [updatedRequest],
          });
        }
      });
    });
  }, []);

  const sortby = useKanbanStorePersist((state) => state.sortby);
  const dateRange = useKanbanStorePersist((state) => state.filters.dateRange);
  const queryValuesMap = useKanbanStorePersist((state) => state.queryValuesMap);
  const preselectedRange = useKanbanStorePersist(
    (state) => state.filters.preselectedRange
  );
  const isInternal = searchParams.get("tab") === "internal-helpdesk";
  const closedRequestsShowPeriod = useKanbanStorePersist(
    (state) => state.displayOptions.card.closedRequestsShowPeriod
  );

  const requests = useFocusedLiveQuery(
    async () => {
      const isAnyFilterApplied = Object.keys(queryValuesMap).some((key) => {
        const item = queryValuesMap[key];
        return item.values.length > 0;
      });

      const conditionsList: any[] = [];
      const relationshipConditionsList: any[] = [];
      const crmConditionsList: any[] = [];

      if (isAnyFilterApplied) {
        Object.keys(queryValuesMap).forEach((key) => {
          const filter = queryValuesMap[key];
          if (filter.values.length) {
            if (filter.indexed_key === "relationship_id") {
              const fieldName = getRelationshipFilterKey(key);
              relationshipConditionsList.push((rel: any) => {
                const valueToCheck = rel[fieldName];
                if (Array.isArray(valueToCheck)) {
                  return valueToCheck.some((value) =>
                    filter.values.includes(value)
                  );
                }
                return filter.values.includes(valueToCheck);
              });
            } else if (filter.indexed_key.includes("crm_")) {
              crmConditionsList.push((crmInfo: any) => {
                const valueToCheck = crmInfo[filter.indexed_key];
                if (Array.isArray(valueToCheck)) {
                  return valueToCheck.some((value) =>
                    filter.values.includes(value)
                  );
                }
                return filter.values.includes(valueToCheck);
              });
            } else {
              conditionsList.push((req: any) => {
                const valueToCheck = get(req, filter.indexed_key);
                if (Array.isArray(valueToCheck)) {
                  return valueToCheck.some((value) =>
                    filter.values.includes(value)
                  );
                }
                // case for status + sub_status
                if (filter.indexed_key === "status") {
                  const valueToCheck1 = get(req, "status");
                  const valueToCheck2 = get(req, "sub_status");
                  return (
                    filter.values.includes(valueToCheck1) ||
                    filter.values.includes(valueToCheck2)
                  );
                }
                return filter.values.includes(valueToCheck);
              });
            }
          }
        });
      }

      let updatedRange = dateRange;
      if (preselectedRange) {
        updatedRange = getDateObjectsFromRange(preselectedRange);
      }

      const closedDateRange = getDateObjectsFromRange(
        getDateRangeByPeriod(closedRequestsShowPeriod)
      );

      let dbQuery = getThenaDB()
        .requests.where("created_at_date")
        .between(
          updatedRange?.from
            ? startOfDay(new Date(updatedRange.from))
            : Dexie.minKey,
          updatedRange?.to ? endOfDay(new Date(updatedRange.to)) : Dexie.maxKey,
          true,
          true
        )
        .and((req) => {
          if (!closedDateRange?.from || !closedDateRange?.to) {
            return ["OPEN", "INPROGRESS", "ONHOLD", "CLOSED"].includes(
              req.status
            );
          }
          const nonClosedCheck = ["OPEN", "INPROGRESS", "ONHOLD"].includes(
            req.status
          );
          const closedPeriodCheck =
            req.status === "CLOSED" &&
            req.created_at_date >= startOfDay(new Date(closedDateRange.from)) &&
            req.created_at_date <= endOfDay(new Date(closedDateRange.to));
          return nonClosedCheck || closedPeriodCheck;
        })
        .and((req) => req.is_internal_helpdesk === isInternal);

      conditionsList.forEach((condition) => {
        dbQuery = dbQuery.and(condition);
      });

      const relationIdsPromiseList: any[] = [];

      if (relationshipConditionsList.length) {
        let relationDBQuery = getThenaDB()
          .relationships.where("_id")
          .notEqual("");
        relationshipConditionsList.forEach((condition) => {
          relationDBQuery = relationDBQuery.and(condition);
        });
        relationIdsPromiseList.push(relationDBQuery.primaryKeys());
      }

      if (crmConditionsList.length) {
        let crmDBQuery = crmRelationDB
          .table("crmRelations")
          .where("_id")
          .notEqual("");
        crmConditionsList.forEach((condition) => {
          crmDBQuery = crmDBQuery.and(condition);
        });
        relationIdsPromiseList.push(crmDBQuery.primaryKeys());
      }

      if (relationIdsPromiseList.length) {
        const relationIdList = await Promise.all(relationIdsPromiseList);
        const relationIds = intersection(...relationIdList).flat();
        dbQuery = dbQuery.and((req) =>
          relationIds.includes(req?.relationship_id)
        );
      }

      let nullList: any[] = [];

      if (NULL_CHECK_SORTING_FIELDS.includes(sortby.field)) {
        const queryClone = dbQuery.clone();
        nullList = await queryClone
          .and((req) => req[sortby.field] === "NOT_PRESENT")
          .toArray();
        dbQuery = dbQuery.and((req) => req[sortby.field] !== "NOT_PRESENT");
      }

      if (sortby.order === "ASC") {
        return dbQuery
          .sortBy(sortby.field)
          .then((list) => list.concat(nullList));
      }

      return dbQuery
        .reverse()
        .sortBy(sortby.field)
        .then((list) => list.concat(nullList));
    },
    [
      isInternal,
      dateRange,
      preselectedRange,
      sortby,
      queryValuesMap,
      closedRequestsShowPeriod,
    ],
    []
  );

  // SEARCH LOGIC

  const relationshipIDs = useMemo(() => {
    const ids = requests?.map((req) => req.relationship_id);
    const uniqueIDs = new Set(ids);
    return Array.from(uniqueIDs);
  }, [requests]);

  const allRelations = useFocusedLiveQuery(() => {
    return getThenaDB()
      .relationships.bulkGet(relationshipIDs)
      .then((relationships) =>
        relationships.map((relationship) => {
          return {
            relationship_id: relationship?._id,
            channelName: relationship?.channel_name,
            customerName: relationship?.customer_name,
          };
        })
      );
  }, [relationshipIDs]);

  const searchData = useMemo(() => {
    return requests?.map((req) => {
      const { request_id, original_message_text, relationship_id } = req;
      const relationObj = allRelations?.find(
        (rel) => rel?.relationship_id === relationship_id
      );
      return {
        originalRequestObject: req,
        original_message_text,
        request_id: request_id?.toString(),
        channelName: relationObj?.channelName,
        customerName: relationObj?.customerName,
      };
    });
  }, [requests, allRelations]);

  const searchQuery = useKanbanStorePersist((state) => state.searchQuery);

  const searchedRequests = useMemo(() => {
    if (isEmpty(searchQuery)) return [];

    const filteredData = (searchData || []).filter((con) => {
      return [
        con.channelName,
        con.original_message_text,
        con.customerName,
        con.request_id,
      ].some((field) => includesIgnoreCase(field, searchQuery));
    });
    return filteredData.map((con) => con.originalRequestObject);
  }, [searchQuery, searchData]);

  const isSearchAvailable = searchQuery.length > 0;

  const mutateAPICount = useKanbanStore((state) => state.mutateAPICount);
  const isMutateAPIPending = mutateAPICount > 0;

  useEffect(() => {
    const init = async () => {
      if (isMutateAPIPending) {
        return;
      }

      const requestIds = (
        isSearchAvailable ? searchedRequests : requests || []
      ).map((req) => req._id);

      const currentRequestsFromDB =
        await getThenaDB().requests.bulkGet(requestIds);

      const oldRequestMap = useKanbanStore.getState().requestMap;

      const newRequestMap = currentRequestsFromDB.reduce(
        (acc: RequestType, item) => {
          if (!item) {
            return acc;
          }
          const oldItem = oldRequestMap[item._id];
          if (
            oldItem &&
            oldItem.updated_at_date &&
            item.updated_at_date &&
            item.updated_at_date <= oldItem.updated_at_date
          ) {
            acc[item._id] = oldItem;
          } else {
            acc[item._id] = item;
          }
          return acc;
        },
        {} as RequestType
      );

      useKanbanStore.dispatch({
        type: "SET_REQUEST_MAP",
        payload: {
          requestMap: newRequestMap,
        },
      });
    };

    init();
  }, [requests, searchedRequests, isSearchAvailable, isMutateAPIPending]);

  useEffect(() => {
    let timerId: null | NodeJS.Timeout = null;
    if (requests?.length) {
      useGlobalStore.getState().setIsKanbanReady(true);
    } else {
      timerId = setTimeout(() => {
        useGlobalStore.getState().setIsKanbanReady(true);
      }, 20 * 1000);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [requests?.length]);

  /**
   * INSERT TO STORE
   */

  const subStatuses = useFocusedLiveQuery(() => {
    return getThenaDB()
      .subStatuses.where("parent")
      .anyOf(STATUS_ORDER)
      .toArray();
  }, []);

  const columnOrder = useFocusedLiveQuery(() => {
    return getThenaDB()
      .views.where("name")
      .equals(views.KANBAN_COLUMN_ORDER)
      .first()
      .then((response) => response?.additionalPrefs?.ordered_columns);
  }, []);

  const currentViewDetails = useFocusedLiveQuery(() => {
    return getThenaDB().views.get(currentView ?? "");
  }, [currentView]);

  const defaultViewDetails = useFocusedLiveQuery(() => {
    return getThenaDB()
      .views.where("name")
      .anyOf(["KANBAN_DISPLAY_OPTIONS", "HELPDESK_DISPLAY_OPTIONS"])
      .toArray();
  }, []);

  const getTableMetadata = useCallback(() => {
    /**
     * Get current views column metadata
     * If no view is selected, get default view column metadata
     */
    const searchParams = new URLSearchParams(window.location.search);
    const viewID = searchParams.get("view");
    let columnMetadata:
      | ViewsType["additionalPrefs"]["columnMetadata"]
      | undefined;
    if (viewID) {
      columnMetadata = currentViewDetails?.additionalPrefs?.columnMetadata;
    }

    if (!viewID) {
      const tabName = searchParams.get("tab");
      if (tabName === "internal-helpdesk") {
        columnMetadata = defaultViewDetails?.find(
          (item) => item.name === "HELPDESK_DISPLAY_OPTIONS"
        )?.additionalPrefs?.columnMetadata;
      } else {
        columnMetadata = defaultViewDetails?.find(
          (item) => item.name === "KANBAN_DISPLAY_OPTIONS"
        )?.additionalPrefs?.columnMetadata;
      }
    }

    // If no metadata is found for the view, set default metadata
    if (!columnMetadata) {
      columnMetadata = {} as ViewsType["additionalPrefs"]["columnMetadata"];
      STATUS_ORDER.forEach((status) => {
        columnMetadata![status] = { isHidden: false };
      });

      subStatuses?.forEach((subStatus) => {
        // TODO: change subStatus.name to subStatus._id when we start storing id's instead of name
        columnMetadata![subStatus.name] = { isHidden: false };
      });
    }

    return columnMetadata;
  }, [currentViewDetails, defaultViewDetails, subStatuses]);

  const requestMap = useKanbanStore((state) => state.requestMap);

  useEffect(() => {
    const list = Object.values(requestMap) as unknown as RequestType[];
    const statusOrderMap = {};
    const columnMetadata = getTableMetadata();
    let finalColumnOrder = {};

    if (!isEqual(columnOrder, subStatuses)) {
      Object.keys(columnOrder || DEFAULT_COLUMN_ORDER).forEach((key) => {
        const subStatusesOfParent = subStatuses?.filter(
          (status) => status?.parent === key
        );

        finalColumnOrder[key] = union(
          columnOrder?.[key],
          subStatusesOfParent?.map((status) => status._id)
        );
      });
    } else {
      finalColumnOrder = columnOrder;
    }

    STATUS_ORDER.forEach((parentStatus) => {
      const subStatusIds = finalColumnOrder?.[parentStatus] ?? [];

      const subStatusesOfParent = subStatusIds
        .map((statusId) =>
          subStatuses?.filter((subStatus) => subStatus._id === statusId)
        )
        .flat();

      if (subStatusesOfParent?.length) {
        subStatusesOfParent.forEach((item) => {
          if (!item) {
            return;
          }
          const requests = list.filter(
            (request) => request.sub_status === item._id
          );
          statusOrderMap[item.name] = requests.map((request) => request._id);
        });
      } else {
        statusOrderMap[parentStatus] = list
          .filter((item) => item.status === parentStatus)
          .map((item) => item._id);
      }
    });
    useKanbanStore.dispatch({
      type: "SET_STATUS_ORDER_MAP",
      payload: { statusOrderMap, columnMetadata },
    });

    useKanbanCacheStore.getState().setSubStatusCache(subStatuses || []);
  }, [requestMap, columnOrder, subStatuses, getTableMetadata]);

  const requestsMeta = useMemo(() => {
    return requests.reduce(
      (acc, req) => {
        if (!req) return acc;
        acc.relationIds.push(req?.relationship_id || "");
        acc.assigneeIds.push(req?.assigned_to_user_id || "");
        acc.creatorIds.push(req?.original_message_user_id || "");
        return acc;
      },
      {
        relationIds: [],
        assigneeIds: [],
        creatorIds: [],
      }
    );
  }, [requests]);

  const setRelationCache = useKanbanCacheStore(
    (state) => state.setRelationCache
  );

  const setUserCache = useKanbanCacheStore((state) => state.setUserCache);
  const setCustomerCache = useKanbanCacheStore(
    (state) => state.setCustomerCache
  );

  const cardDisplayOptions = useKanbanStorePersist(
    (store) => store.displayOptions.card
  );

  const drawerDisplayOptions = useKanbanStorePersist(
    (store) => store.displayOptions.drawer
  );

  const cardCRMIds = useMemo(() => {
    return cardDisplayOptions?.visibleCrmFields?.map((item) => item.value);
  }, [cardDisplayOptions?.visibleCrmFields]);

  const drawerCRMIds = useMemo(() => {
    return drawerDisplayOptions?.visibleCrmFields?.map((item) => item.value);
  }, [drawerDisplayOptions.visibleCrmFields]);

  const selectedCRMIds = useMemo(() => {
    return Array.from(
      new Set([...(cardCRMIds || []), ...(drawerCRMIds || [])])
    );
  }, [cardCRMIds, drawerCRMIds]);

  const crmInfo = useFocusedLiveQuery(() => {
    return getThenaDB()
      .crmFields.toArray()
      .then((res) => {
        const currentCRM = res[0] || {};
        const result = {
          crm: currentCRM.crm ?? "",
          fields: [] as any[],
        };
        const redactedKeyList = selectedCRMIds.map(getRedactedKey);
        currentCRM.fields?.forEach((field) => {
          if (redactedKeyList.includes(field.name)) {
            result.fields.push({
              field_name: field.label,
              _id: getFormattedKey(field.name),
            });
          }
        });
        return result;
      });
  }, [selectedCRMIds]);

  const crmAccountOwnerValues = useFocusedLiveQuery(
    () => {
      return getThenaDB()
        .filters.toArray()
        .then((list) => {
          return (
            list.find(
              (item) =>
                item.indexed_key === "crm_hubspot_owner_id" ||
                item.indexed_key === "crm_OwnerId"
            )?.values || []
          );
        });
    },
    [],
    []
  );

  useFocusedLiveQuery(() => {
    return getThenaDB()
      .relationships.bulkGet(requestsMeta?.relationIds || [])
      .then((res) => {
        const map = res.reduce((acc, relation) => {
          if (!relation) return acc;
          const crmValuesList: any[] = [];
          const crmObject = relation?.crm_values?.sinked_crm_object || {};

          crmInfo?.fields?.forEach((field) => {
            const crmKey = getRedactedKey(field._id);
            const valueToAdd = crmObject[crmKey];
            let resultList;
            if (Array.isArray(valueToAdd)) {
              resultList = [...getFormattedOverflowValue(valueToAdd)];
            } else {
              resultList = [getFormattedOverflowValue(valueToAdd)];
            }

            crmValuesList.push({
              ...field,
              values: resultList,
            });
          });

          acc[relation._id] = {
            connectedCRM: crmInfo?.crm,
            crmValuesList,
            channel_name: relation.channel_name,
            customer_name: relation.customer_name,
            customer_team_icon: relation.customer_team?.icon?.image_68 || "",
            channel_id: relation.channel_id,
            channel_is_private: relation.channel_is_private,
          };
          return acc;
        }, {});
        setRelationCache(map);
      });
  }, [requestsMeta?.relationIds, crmInfo, crmAccountOwnerValues]);

  useFocusedLiveQuery(() => {
    return getThenaDB()
      .customerContacts.where("user_id")
      .anyOf(requestsMeta?.creatorIds || [])
      .toArray()
      .then((res) => {
        const map = res.reduce((acc, item) => {
          if (!item || !item?.user_id) return acc;
          acc[item.user_id] = {
            name: item.name,
            slack_profile_image_24:
              item?.metadata?.slack?.profile_image_48 || "",
          };
          return acc;
        }, {});
        setCustomerCache(map);
      });
  }, [requestsMeta?.creatorIds]);

  useFocusedLiveQuery(() => {
    return getThenaDB()
      .users.bulkGet(requestsMeta?.assigneeIds || [])
      .then((res) => {
        const map = res.reduce((acc, item) => {
          if (!item || !item?._id) return acc;
          acc[item._id] = {
            name: item.name,
            slack_profile_image_24: item.slack_profile_image_24,
          };
          return acc;
        }, {});
        setUserCache(map);
      });
  }, [requestsMeta?.assigneeIds]);

  useEffect(() => {
    return () => {
      useKanbanCacheStore.getState().resetCache();
    };
  }, []);
};

export default useRequestsInit;
