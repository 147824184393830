import { fetchUser } from "@/api/auth";
import LogRocket from "logrocket";
import { BASE_DB_SCHEMA, DexieType, getThenaDB, updateThenaDB } from "@/db";
import { metaDB } from "@/db/meta";
import useAuthInit from "@/hooks/useAuthInit";
import Login from "@/pages/Login";
import { useAuthStorePersist } from "@/store/authStorePersist";
import {
  clearBrowserStorage,
  getIsOnboardingModalShown,
  setOnboardingModalShownTrue,
  tabId,
} from "@/utils/auth";
import Dexie from "dexie";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LC_CLIENT_KEY } from "@/config/constants";
import { Toaster as SonnerToaster } from "sonner";
import { LDProvider, useLDClient } from "launchdarkly-react-client-sdk";
import LazyCommandbar from "./LazyCommandbar";
import { useGlobalStorePersist } from "@/store/globalStorePersist";
import App from "@/App";
import ThenaLoader from "@/components/common/ThenaLoader";
import CustomerPortalApp from "@/pages/CustomerPortal/App";
import { getIsCustomerPortal, getSubdomainInfo } from "@/utils/domUtils";
import { SettingUp } from "@/pages/SettingUp";
import AddToSlack from "@/pages/AddToSlack";
import * as Sentry from "@sentry/react";
import { CUSTOMER_PORTAL_USER_ROLE } from "@/constants/user";

const REDIRECTION_ROUTES = ["/", "/setting-up", "/slack/callback"];

const AuthLayer = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    function handler(event: PromiseRejectionEvent) {
      event.preventDefault();
      console.log("Unhandled promise rejection:", event.reason);
      // sendErrorToLogRocket({
      //   error: "Unhandled promise rejection:",
      //   extra: {
      //     reason: event.reason,
      //   },
      // });
    }
    window.addEventListener("unhandledrejection", handler);
    return () => {
      window.removeEventListener("unhandledrejection", handler);
    };
  }, []);

  useAuthInit();
  const { pathname } = useLocation();
  const initRef = useRef(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const user = useAuthStorePersist((state) => state.user);

  const isMsTeamAuthResultPage =
    pathname.includes("/configuration/ms-teams/success") ||
    pathname.includes("/configuration/ms-teams/failed");

  useEffect(() => {
    const channel = new BroadcastChannel("auth_channel");
    channel.onmessage = async (event) => {
      if (event.data.type === "LOGOUT" && event.data.tabId !== tabId) {
        await clearBrowserStorage();
        // window.location.reload();
        // window.location.href = LOGOUT_URL;
      }
    };
    return () => {
      channel.close();
    };
  }, []);

  const [showWelcomePage, setShowWelcomePage] = useState(false);
  const [showSyncPage, setShowSyncPage] = useState(false);

  useEffect(() => {
    const channel = new BroadcastChannel("reload_channel");
    channel.onmessage = async (event) => {
      if (event.data.type === "RELOAD" && event.data.tabId !== tabId) {
        window.location.reload();
      }
    };
    return () => {
      channel.close();
    };
  }, []);

  const init = async () => {
    if (initRef.current) return;
    try {
      setLoading(true);
      initRef.current = true;
      const res = await fetchUser();
      if (res._id) {
        LogRocket.identify(res._id, {
          name: res.displayName,
          email: res.email,
          team: res.team_name,
          teamId: res.team_id,
        });

        Sentry.setContext("user", {
          name: res.displayName,
          email: res.email,
          team: res.team_name,
          teamId: res.team_id,
          userId: res.user_id,
        });

        if (user?._id && res._id !== user._id) {
          await clearBrowserStorage();
          window.location.reload();
          return;
        }
        const customerPortal = res?.roles?.includes?.(
          CUSTOMER_PORTAL_USER_ROLE
        );

        let shouldInitDB = true;

        if (customerPortal) {
          console.log("Customer Portal Flow: ", getSubdomainInfo().subdomain);
          shouldInitDB = false;
        }

        useAuthStorePersist.dispatch({ type: "INIT", payload: { user: res } });

        if (!res?._id && shouldInitDB) {
          const dbName = `ThenaDB_${res.id}_${res.team_id}`;
          const thenaDB = new Dexie(dbName) as DexieType;
          thenaDB.version(thenaDB.verno || 1).stores(BASE_DB_SCHEMA);
          await thenaDB.open();
          updateThenaDB(thenaDB);

          useGlobalStorePersist.dispatch({
            type: "SET_DB_VERSION",
            payload: {
              dbVersion: thenaDB.verno,
            },
          });

          useGlobalStorePersist.dispatch({
            type: "SET_DB_SCHEMA",
            payload: {
              dbSchema: BASE_DB_SCHEMA,
            },
          });

          metaDB.meta.put({
            dbName,
            staleState: false,
            createdAt: Date.now().toString(),
            lastSyncedAt: Date.now().toString(),
          });
        }

        if (!res?.isThenaInstalled) {
          setShowWelcomePage(true);
        } else if (
          !res.primaryInstallation ||
          res.primaryInstallation?.onboarding?.channelsSynced === false ||
          res.primaryInstallation?.onboarding?.membersSynced === false
        ) {
          if (!customerPortal) {
            setShowSyncPage(true);
          }
        } else if (
          res?.isFirstLogin &&
          !res.finishedOnBoarding &&
          !getIsOnboardingModalShown()
        ) {
          navigate("/requests?from=onboarding");
          setOnboardingModalShownTrue();
        } else if (REDIRECTION_ROUTES.includes(pathname)) {
          navigate("/requests");
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      useAuthStorePersist.dispatch({ type: "RESET" });
      if (!isMsTeamAuthResultPage) {
        if (pathname === "/customer-portal/login") {
          return;
        }
        navigate("/");
      }
    }
  };

  init();

  const initDB = async () => {
    const dbName = `ThenaDB_${user.id}_${user.team_id}`;
    const thenaDB = new Dexie(dbName) as DexieType;
    const dbVersion = useGlobalStorePersist.getState().dbVersion;
    const dbSchema = useGlobalStorePersist.getState().dbSchema;
    thenaDB.version(dbVersion).stores(dbSchema);
    updateThenaDB(thenaDB);
  };

  const getLDContext = () => {
    if (user) {
      return {
        kind: "multi", // LDMultiKindContext
        ["installation-team-id"]: {
          key: user.primaryInstallation?.team?.id,
        },
        user: {
          key: user.primaryInstallation?.user?.id,
        },
      };
    }
  };

  const ldClient = useLDClient();

  useEffect(() => {
    if (user && !user.superUser) {
      (async () => {
        await ldClient?.identify({
          kind: "multi", // LDMultiKindContext
          ["installation-team-id"]: {
            key: user.primaryInstallation?.team?.id,
            name: user.primaryInstallation?.team?.name,
          },
          user: {
            key: user?.id,
          },
        });
      })();
    }
  }, [ldClient, user]);

  if (loading) {
    return <ThenaLoader />;
  }

  if (showWelcomePage) {
    return <AddToSlack />;
  }

  if (isMsTeamAuthResultPage) {
    return <App />;
  }

  if (showSyncPage) {
    return <SettingUp />;
  }

  if (user._id) {
    if (getThenaDB() === null && !getIsCustomerPortal()) {
      initDB();
    }

    if (getIsCustomerPortal()) {
      return (
        <>
          <CustomerPortalApp />
          <SonnerToaster richColors duration={3000} />
        </>
      );
    }

    return (
      <LDProvider
        clientSideID={LC_CLIENT_KEY}
        deferInitialization={true}
        context={getLDContext()}
      >
        {children}
        <LazyCommandbar />
      </LDProvider>
    );
  }

  if (loading) {
    return <ThenaLoader />;
  }

  return <Login />;
};

export default AuthLayer;
